import React from "react"
import styled from "styled-components"
import Sample from "./Sample"

const Samples = ({ samples, title }) => {
  return (
    <Wrapper>
      <section className="samples">
        <h3 className="samples-title">{title}</h3>
        <div className="samples-center">
          <article>
            {samples.map(sample => {
              return <Sample key={sample.id} {...sample} />
            })}
          </article>
        </div>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .samples {
    width: 85vw;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-bottom: 4rem;
  }
  .samples-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #e12d39;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: 992px) {
    .samples {
      width: 92vw;
    }
  }
  @media screen and (min-width: 1170px) {
    .samples-center {
      display: grid;
      grid-template-columns: 1fr 250px;
      column-gap: 1rem;
    }
  }
`

export default Samples
