import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Samples from "../components/Samples"

const SamplesPage = ({ location, data }) => {
  //console.log("SamplesPage location=", location)
  const {
    allFile: { nodes: childMdxes },
  } = data
  const samples = childMdxes.map(child => {
    return child.childMdx
  })
  return (
    <Layout location={location}>
      <SEO title="Samples" description="Web Component Samples Kim and Hwang" />
      <Samples samples={samples} title="all samples" />
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "samples" }
        extension: { eq: "mdx" }
      }
      sort: { fields: childMdx___frontmatter___title }
    ) {
      nodes {
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            author
            category
            date
            readTime
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
          body
        }
      }
    }
  }
`
export default SamplesPage
